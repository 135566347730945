// Handle remote forms with with GET form requests.
$(document).on("submit", "form[method=get][data-turbo-get=true]", e=> {
  let params_string;
  e.preventDefault();
  const form = $(e.target);
  const params = form.serializeArray().filter(param => param["value"]);

  let url = form.attr("action");

  if (params_string = $.param(params)) {
    if (url.includes("?")) {
      url += `&${params_string}`;
    } else {
      url += `?${params_string}`;
    }
  }

  if (form.attr('target') == '_blank') {
    window.open(url, form.attr('target'), form.attr('rel'));
  } else {
    Turbo.visit(url, { action: form.data("turbo-action") || "advance" });
  }
});


// NOTE: `.activate-submit-on-change` class should be a parent element to the form(s)
// The event is binded to this parent element rather than the form itself so that the form
// can be replaced when returning JS from controller actions for form posts or updates. The
// event remains active since it's bound on the static parent element rather than the dynamic
// form that can be replaced in the DOM
$(document).on("turbo:load", () => {
  Array.from($(".activate-submit-on-change")).map((element) => new ActivateSubmitOnChange($(element)))
});

class ActivateSubmitOnChange {
  constructor(element) {
    this.init_change_watcher = this.init_change_watcher.bind(this);
    this.container = element;
    this.init_change_watcher();
  }

  init_change_watcher() {
    this.container.on("change input", "form input", e => {
      if (($(e.currentTarget).attr("required")) && (e.currentTarget.value.length === 0)) {
        this.handle_form_changes($(e.currentTarget.form), false);
      } else {
        this.handle_form_changes($(e.currentTarget.form), true);
      }
    });

    this.container.on("change", "form select", e => {
      this.handle_form_changes($(e.currentTarget.form), true);
    });
  }

  handle_form_changes(target, enabled) {
    let submit_button_target;
    let submit_button = undefined;

    if (submit_button_target = target.data("submit-button")) {
      submit_button = $(submit_button_target);
    } else {
      submit_button = target.find("input[type='submit']");
    }

    if (submit_button) {
      if (enabled) {
        submit_button.removeClass("disabled");
        submit_button.prop("disabled", false);
      } else {
        submit_button.addClass("disabled");
        submit_button.prop("disabled", true);
      }
    }
  }
}
