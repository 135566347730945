$(document).on("click", "[data-scroll-to]", e => {
  // Note: these parameters will only be handled when a `useAutoScroll(trigger)` hook is
  // present in a React component, whenever `trigger` evaluates to `true`. Use `flash[:scroll_to]`
  // for any non-React auto-scrolling.
  window.loomlyScroll = {
    ...window.loomlyScroll,
    scroll_to: $(e.currentTarget).data("scroll-to"),
    scroll_offset: $(e.currentTarget).data("scroll-offset"),
  };

  if (window.loomlyScroll.restorationData) {
    delete window.loomlyScroll.restorationData[$(e.currentTarget).attr("href")];
  }
});

$(document).on("click", "[data-scroll-reset]", e => {
  if (window.loomlyScroll?.restorationData) {
    delete window.loomlyScroll.restorationData[$(e.currentTarget).attr("href")];
  }
});
