// Compatibility shim for old native mobile apps clients using turbolinks
// Borrowed from: https://github.com/hotwired/turbo-rails/blob/main/UPGRADING.md#5-optional-provide-backwards-compatible-shims-for-mobile-adapters
// This could be removed after we make sure we don't have any more old native mobile app clients in use.
window.Turbolinks = {
  visit: Turbo.visit,

  controller: {
    isDeprecatedAdapter(adapter) {
      return typeof adapter.visitProposedToLocation !== "function";
    },

    startVisitToLocationWithAction(location, action, restorationIdentifier) {
      window.Turbo.navigator.startVisit(location, restorationIdentifier, { action });
    },

    get restorationIdentifier() {
      return window.Turbo.navigator.restorationIdentifier;
    },

    get adapter() {
      return window.Turbo.navigator.adapter;
    },

    set adapter(adapter) {
      adapter.visitProposedToLocation = function(location, options) {
        adapter.visitProposedToLocationWithAction(location, options.action);
      };

      window.Turbo.registerAdapter(adapter);
    }
  }
};

document.addEventListener("turbo:load", function() {
  const event = new CustomEvent("turbolinks:load", { bubbles: true });
  document.documentElement.dispatchEvent(event);
});
