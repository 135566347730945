$(document).on("turbo:load", ()=> {
  const element = $(".filter-modal-container");
  if (element) { new PostFiltering(element); }
});

class PostFiltering {
  showPreferenceMessage(message, options) {
    if (options == null) { options = {}; }
    if (options.persist) {
      this.element
        .find(".filter-preferences-message")
        .finish()
        .fadeOut(0)
        .fadeIn(500)
        .text(message);
    } else {
      this.element
        .find(".filter-preferences-message")
        .finish()
        .text(message)
        .fadeOut(0)
        .fadeIn(500)
        .delay(5000)
        .fadeOut(1000);
    }
  }

  constructor(element) {
    this.element = element;

    $("#filter-modal").on("show.bs.modal", () => {
      if ($("#filter-modal").data("using-preferences")) {
        this.showPreferenceMessage("Saved preferences applied", {persist: true});
      }
    });

    const applyFilterButton = element.find("#apply-filter-button");
    applyFilterButton.click(() => {
      $("#filter-modal").hide();
    });

    const preferencesButton = element.find(".filter-preferences-button");

    const calendar_id = element
      .find(".filter-posts-form")
      .data("calendarId");

    preferencesButton.click(() => {
      this.element.find(".filter-preferences-actions .dropdown-toggle").prop("disabled", true);
      preferencesButton.prop("disabled", true);
      preferencesButton.text("Saving...");

      const query = element.find(".filter-posts-form").serialize();

      $.post(`/calendars/${calendar_id}/post_filter_preferences`, { query }).then(() => {
        Turbo.visit(preferencesButton.data("returnTo"), { action: preferencesButton.data("turbo-action") || "advance" });
      });
    });
  }
}
