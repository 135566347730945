$(document).on('turbo:load', () => $(".multi-select-dropdown").map((i, element)=> new MultiSelectDropdown($(element))));

class MultiSelectDropdown {
  constructor(element) {
    this.handle_entry_click = this.handle_entry_click.bind(this);
    this.set_display_value = this.set_display_value.bind(this);
    this.container = element;
    this.set_display_value();
    this.container.on("click", ".entry", this.handle_entry_click);
  }

  handle_entry_click(e) {
    e.stopPropagation();
    if (!$(e.target).is("input")) { return; }
    this.set_display_value();
  }

  set_display_value() {
    const checked_values = this.container
      .find(".entry input[type=checkbox]:checked")
      .map((i, el)=> $(el).attr("data-display-name"))
      .toArray()
      .join(", ");

    const selected_options_display_name = this.container.find(".selected-options-display-name");

    if (checked_values) {
      selected_options_display_name.text(checked_values);
    } else {
      const default_display_name = selected_options_display_name.data("default-display-name");
      selected_options_display_name.text(default_display_name);
    }
  }
}
