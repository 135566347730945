import "tablesorter";

let translateAnalyticsHeadersHandler = null;

$(document).on('turbo:load', function() {
  // Remove scroll listener related to sticky table headers
  if (translateAnalyticsHeadersHandler) { $(document).off("scroll", translateAnalyticsHeadersHandler); }
  if ($("#calendar-analytics-header").length) { new AnalyticsHeader($("#calendar-analytics-header")); }
  if ($("#calendar-analytics-container").length) { new AnalyticsReport($("#calendar-analytics-container")); }

  $("#post-container .post-analytics-panel").each((i, el)=> {
    new PostAnalyticsPanel($(el));
  });
});

class AnalyticsHeader {
  constructor(element){
    this.init_date_filtering = this.init_date_filtering.bind(this);
    this.container = element;
    this.init_date_filtering();
  }

  init_date_filtering() {
    const filtering = this.container.find(".filter-options");
    filtering.find(".btn.cancel").click(e=> {
      e.preventDefault();
      filtering.removeClass("open");
    });
  }
}


class AnalyticsReport {
  constructor(element){
    this.init_table_sorting = this.init_table_sorting.bind(this);
    this.init_table_sticky_header = this.init_table_sticky_header.bind(this);
    this.init_clicks_by_group_expansion = this.init_clicks_by_group_expansion.bind(this);
    this.init_auto_fetch_post_analytics = this.init_auto_fetch_post_analytics.bind(this);
    this.init_auto_fetch_story_analytics = this.init_auto_fetch_story_analytics.bind(this);
    this.monitor_fetch_complete = this.monitor_fetch_complete.bind(this);
    this.container = element;
    this.base_url = this.container.attr("data-base-url");
    this.provider = this.container.attr("data-provider");
    this.subscription = null;
    this.init_table_sorting();
    this.init_table_sticky_header();
    this.init_clicks_by_group_expansion();
    this.init_auto_fetch_account_analytics();
    this.init_auto_fetch_post_analytics();
    this.init_auto_fetch_story_analytics();
    if (this.base_url) { this.monitor_fetch_complete(); }
  }

  // Using https://github.com/Mottie/tablesorter
  init_table_sorting() {
    this.container.find(".analytics-table table").tablesorter({
      sortInitialOrder: "desc",
      headerTemplate: "{content} {icon}",
      cssIconNone: "fa fa-sort-amount-desc",
      cssIconAsc: "fa fa-sort-amount-asc",
      cssIconDesc: "fa fa-sort-amount-desc",
      headers: {
        1: { sorter: false }
      }
    });  // Disable sorting post
  }

  init_table_sticky_header() {
    if (translateAnalyticsHeadersHandler) { $(document).off("scroll", translateAnalyticsHeadersHandler); }

    const headers = this.container.find(".analytics-table table thead");
    const scrollingContainer = $('#wrapping-container');
    const subheaderHeight = $(".subheader").outerHeight();

    if (headers.length > 0 && scrollingContainer.length > 0) {
      translateAnalyticsHeadersHandler = () => {
        const scrollTop = $(document).scrollTop();

        $(headers).each((_i, el) => {
          const header = $(el);
          const tableWrapper = header.closest(".analytics-table");
          const headerTop = tableWrapper.offset().top;

          if (scrollTop + subheaderHeight > headerTop) {
            const yTranslation = Math.floor(Math.abs(scrollTop + subheaderHeight - headerTop));
            header.css('transform', `translateY(${yTranslation}px)`);
          } else {
            header.css('transform', '');
          }
        });
      };

      $(document).on('scroll', translateAnalyticsHeadersHandler);
    }
  }

  init_clicks_by_group_expansion() {
    this.container.find(".clicks-by-group").on("click", ".show-more a", e => {
      e.preventDefault();
      $(e.currentTarget).closest(".clicks-by-group").addClass("expanded");
    });
  }

  init_auto_fetch_account_analytics() {
    const panel = this.container.find(".account-overview");

    if (panel.is("[data-auto-fetch='true']")) {
      $.ajax({
        url: `${this.base_url}/${this.provider}/fetch_account`,
        type: "POST",
        beforeSend: ()=> panel.addClass("fetching"),
        error: (data, status, xhr)=> {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();

          if (data && data.responseJSON && data.responseJSON.errors && data.responseJSON.errors[0]) {
            panel.find(".fetch-error").text(data.responseJSON.errors.join(", "));
          }
        }
      });

      // Time out / fail the operation if we don't have a fetch update after 15 seconds:
      setTimeout(()=> {
        if (panel.hasClass("fetching")) {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();
        }
      }, 15000);
    }
  }

  init_auto_fetch_post_analytics() {
    const panel = this.container.find(".posts-overview");

    if (panel.is("[data-auto-fetch='true']")) {
      $.ajax({
        url: `${this.base_url}/${this.provider}/fetch_posts`,
        type: "POST",
        beforeSend: ()=> panel.addClass("fetching"),
        error: (data, status, xhr)=> {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();

          if (data && data.responseJSON && data.responseJSON.errors && data.responseJSON.errors[0]) {
            panel.find(".fetch-error").text(data.responseJSON.errors.join(", "));
          }
        }
      });

      // Time out / fail the operation if we don't have a fetch update after 15 seconds:
      setTimeout(()=> {
        if (panel.hasClass("fetching")) {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();
        }
      }, 15000);
    }
  }

  init_auto_fetch_story_analytics() {
    const panel = this.container.find(".stories-overview");

    if (panel.is("[data-auto-fetch='true']")) {
      $.ajax({
        url: `${this.base_url}/${this.provider}/fetch_stories`,
        type: "POST",
        beforeSend: ()=> panel.addClass("fetching"),
        error: (data, status, xhr)=> {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();

          if (data && data.responseJSON && data.responseJSON.errors && data.responseJSON.errors[0]) {
            panel.find(".fetch-error").text(data.responseJSON.errors.join(", "));
          }
        }
      });

      // Time out / fail the operation if we don't have a fetch update after 15 seconds:
      setTimeout(()=> {
        if (panel.hasClass("fetching")) {
          panel.removeClass("fetching");
          panel.find(".fetch-error").show();
        }
      }, 15000);
    }
  }

  monitor_fetch_complete() {
    this.subscription = App.cable.subscriptions.create({ channel: "AnalyticsChannel", calendar_id: this.base_url.split("/")[2], provider: this.provider }, {
      rejected: () => console.error("Analytics fetch post subscription rejected"),
      received: data => {
        // If this is a post/story/account-specific fetch, dynamically replace just the related panel. Otherwise, this likely
        // represents a full fetch after activation or manual fetching, so we should just reload the
        // entire page:
        //
        if (data.fetch_type == "account") {
          const panel = this.container.find(".account-overview");

          if (data.type == "fetch_successful") {
            const df = panel.attr("data-df");
            const dt = panel.attr("data-dt");
            const labelIds = panel.attr("data-label-ids");

            $.ajax({
              url: `${this.base_url}/${this.provider}/account_table?df=${df}&dt=${dt}&label_ids=${labelIds}`,
              type: "GET",
              success: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").hide();
                panel.find(".account-table").replaceWith(data);
                this.init_table_sorting();
                this.init_table_sticky_header();
              },
              error: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").show();
              }
            });
          } else if (data.type == "fetch_failed") {
            panel.find(".fetch-error").show();
          }

        } else if (data.fetch_type == "post") {
          const panel = this.container.find(".posts-overview");

          if (data.type == "fetch_successful") {
            const df = panel.attr("data-df");
            const dt = panel.attr("data-dt");
            const labelIds = panel.attr("data-label-ids");

            $.ajax({
              url: `${this.base_url}/${this.provider}/posts_table?df=${df}&dt=${dt}&label_ids=${labelIds}`,
              type: "GET",
              success: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").hide();
                panel.find(".analytics-table").replaceWith(data);
                this.init_table_sorting();
                this.init_table_sticky_header();
              },
              error: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").show();
              }
            });
          } else if (data.type == "fetch_failed") {
            panel.find(".fetch-error").show();
          }
        } else if (data.fetch_type == "story") {
          const panel = this.container.find(".stories-overview");

          if (data.type == "fetch_successful") {
            const df = panel.attr("data-df");
            const dt = panel.attr("data-dt");
            const labelIds = panel.attr("data-label-ids");

            $.ajax({
              url: `${this.base_url}/${this.provider}/stories_table?df=${df}&dt=${dt}&label_ids=${labelIds}`,
              type: "GET",
              success: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").hide();
                panel.find(".analytics-table").replaceWith(data);
                this.init_table_sorting();
                this.init_table_sticky_header();
              },
              error: (data, status, xhr)=> {
                panel.removeClass("fetching");
                panel.find(".fetch-error").show();
              }
            });
          } else if (data.type == "fetch_failed") {
            panel.find(".fetch-error").show();
          }
        } else if (!$("#export-content-modal").is(":visible")) {     // If we're in PDF export context, stay where we are (handled by modal)
          Turbo.visit(window.location, { action: "replace" });
        }
      }
    });
  }
}

class PostAnalyticsPanel {
  constructor(element) {
    this.init_click_to_refresh = this.init_click_to_refresh.bind(this);
    this.refresh = this.refresh.bind(this);
    this.assign_instagram_url = this.assign_instagram_url.bind(this);
    this.monitor_fetch_complete = this.monitor_fetch_complete.bind(this);
    this.container = element;
    this.service = this.container.attr("data-service");
    this.calendar_id = this.container.attr("data-calendar-id");
    this.post_type = this.container.attr("data-post-type");
    this.post_url = $("#post-container").attr("data-post-url");
    this.subscription = null;
    this.init_click_to_refresh();
  }

  init_click_to_refresh() {
    this.container.find("a.refresh-analytics").click(e => {
      e.preventDefault();
      this.refresh();
    });

    this.container.find("a.assign-instagram-url").click(e => {
      e.preventDefault();
      this.assign_instagram_url();
    });
  }

  refresh() {
    const fetchVariant = this.post_type == "story" ? "stories" : "posts";

    $.ajax({
      url: `/calendars/${this.calendar_id}/analytics/${this.service}/fetch_${fetchVariant}`,
      type: "POST",
      beforeSend: ()=> {
        this.container.find(".refresh-panel").hide();
        this.container.find(".loading").show();
        this.container.find(".panel-header").show();
        this.monitor_fetch_complete();
      },
      error: (data, status, xhr)=> {
        this.container.find(".loading").hide();
        this.container.find(".panel-header").hide();
        this.container.find(".refresh-panel").show();
        this.container.find(".fetch-error").show();

        if (data && data.responseJSON && data.responseJSON.errors && data.responseJSON.errors[0]) {
          panel.find(".fetch-error").text(data.responseJSON.errors.join(", "));
        }
      }
    });

    // Time out / fail the operation if we don't have a fetch update after 15 seconds:
    setTimeout(()=> {
      if (this.container.find(".loading").is(":visible")) {
        this.container.find(".loading").hide();
        this.container.find(".fetch-error").show();
      }
    }, 15000);
  }

  assign_instagram_url() {
    const instagram_url = this.container.find("input[name='url']").val();

    $.ajax({
      url: `${this.post_url}/assign_instagram_url?url=${instagram_url}`,
      type: "POST",
      beforeSend: () => {
        this.container.find(".spinner").show();
      },
      success: (data, status, xhr)=> {
        this.container.find(".instagram-panel").hide();
        this.refresh();
      },
      error: (data, status, xhr)=> {
        this.container.find(".spinner").hide();
        alert(`Sorry, but we were unable to load analytics for this post. You may want to try re-connecting to ${this.service} in your calendar's settings.`);
      }
    });
  }

  monitor_fetch_complete() {
    this.subscription = App.cable.subscriptions.create({ channel: "AnalyticsChannel", calendar_id: this.calendar_id, provider: this.service }, {
      rejected: () => console.error("Analytics fetch post subscription rejected"),
      received: data => {
        if (["post", "story"].includes(data.fetch_type)) {
          if (data.type == "fetch_successful") {
            $.ajax({
              url: `${this.post_url}/analytics_panel?service=${this.service}`,
              type: "GET",
              success: (data, status, xhr)=> {
                this.container.replaceWith(data);
              },
              error: (data, status, xhr)=> {
                this.container.find(".loading").hide();
                this.container.find(".fetch-error").show();
              }
            });
          } else if (data.type == "fetch_failed") {
            this.container.find(".loading").hide();
            this.container.find(".fetch-error").show();
          }

          this.subscription.unsubscribe();
          this.subscription = null;
        }
      }
    });
  }
}
