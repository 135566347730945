export function dismissComment(e, container, callback) {
  const element = $(e.currentTarget).closest(".comment-item");

  element.animate({ opacity: '0' }, 200, ()=> {
    element.animate({ height: '0' }, 200, ()=> {
      element.remove();
      return (callback() != null);
    });
  });
};


export function dismissComments(container, callback) {
  const dismiss_comments = container.find(".clear-notifications");

  dismiss_comments.on("click", e=> {
    e.preventDefault();
    e.stopPropagation();
    const ids = container.find(".comment-item").map((i, comment)=> $(comment).attr("data-id")).toArray();

    $.post("/comments/acknowledge_batch", { ids: ids.join(",") }, data=> {
      container.find(".comment-item").remove();
      dismiss_comments.hide();
      return (callback() != null);
    }).fail((e) => {
      const errorMessage = e?.responseJSON?.error;
      if (errorMessage) {
        alert(errorMessage);
      } else {
        alert("Something went wrong.");
      }
    });
  });
};
