$(document).on('turbo:load', function() {
  if ($("#post-container .post-action-button").length || $("#post-container .workflow-actions").length) {
    new PostActions($("#post-container"));
  }
});

class PostActions {
  constructor(element){
    this.check_for_published_post = this.check_for_published_post.bind(this);
    this.fetchActions = this.fetchActions.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.container = element;
    this.subscription = null;
    this.post_url = this.container.attr("data-post-url");
    this.postId = this.container.data("post-id");
    this.postState = this.container.data("post-state");
    this.check_for_published_post();
  }

  check_for_published_post() {
    if (this.postState && this.postState !== "published") {
      this.subscription = App.cable.subscriptions.create({ channel: "PostStateChannel", id: this.postId, skip_initial: true }, {
        rejected: () => {
          console.error("Post state change update rejected");
        },
        received: data => {
          if (data.state === "published") {
            this.unsubscribe();
            this.fetchActions("action_button", ".post-action-button");
            this.fetchActions("workflow_actions", ".workflow-actions");
          }
        }
      });
    }
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  fetchActions(action, selector) {
    if (this.container.find(selector).length) {
      $.ajax({
        url: `${this.post_url}/${action}`,
        type: "GET",
        error: (data, status, xhr)=> {
          console.error("Received error response from the server when trying to fetch actions");
        },
        success: (data, status, xhr)=> {
          this.container.find(selector).replaceWith(data);
        }
      });
    }
  }
}
