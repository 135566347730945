export default function openPopupWindow(url) {
  const popupWidth = 750;
  const popupHeight = 650;
  const popupLeft = (window.screen.width - popupWidth) / 2;
  const popupTop = (window.screen.height - popupHeight) / 4;
  window.open(
    url,
    'popup',
    `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${[popupTop]}`
  );
};
