$(document).on('turbo:load', ()=> {
  if (!window.Intercom) { return; }

  const intercomDataElement = document.getElementById("intercom-data-tag");
  const intercomData = intercomDataElement ? JSON.parse(intercomDataElement.textContent) : {};

  const visitedPageAttribute = intercomData["visited_page_event"];
  if (visitedPageAttribute) {
    Intercom("update", {
      [`Last Visited ${visitedPageAttribute} At`]: (Date.now()/1000)
    });
  }
});
