const getUrlParams = function() {
  const query = window.location.search.substring(1);
  const raw_vars = query.split("&");

  const params = {};

  for (let v of Array.from(raw_vars)) {
    const [key, val] = Array.from(v.split("="));
    if (key.length > 0) { params[key] = decodeURIComponent(val); }
  }

  return params;
};

$(document).on("turbo:load", ()=> {

  // After a panel expands, the window will scroll to the top of the now opened panel
  $(".panel-scroll-to").on("shown.bs.collapse", function(e) {
    const $panel = $(this).closest(".panel");

    $("html,body").animate({
      scrollTop: $panel.offset().top
    }, 500);
  });


  // Ability to auto show/promote a feature that may not be immediately obvious
  // via a url param
  const auto_shown_feature = getUrlParams()["auto_show_feature"];

  if (auto_shown_feature) {
    switch (auto_shown_feature) {
      case "notify_collaborator":
        $(".show-notify-collaborator-modal").first().click();
        break;
      case "pdf_export":
        $(".show-export-content-modal").first().click();
        break;
    }
  }

  $(document).on("click", "a.click-disabled", function(e) {
    e.preventDefault();
  });
});
