// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

const cable = createConsumer();

window.App || (window.App = {});
window.App.cable = cable;

// Disconnect from websocket on Turbo visit.
// This also unsubscribes from subscriptions
$(document).on("turbo:visit", function() {
  window.App.cable.disconnect();
});

export default cable;
