import Cookies from "js-cookie";

$(document).on('turbo:load', function() {
  $(".global-suppressable-alert[data-name]").on("closed.bs.alert", e=> {
    const alerts = (Cookies.get('suppress_alerts') || "").split(",").filter(x => x.length);
    alerts.push($(e.target).attr("data-name"));
    Cookies.set('suppress_alerts', alerts.join(","), { expires: 300 });
  });

  // NOTE: using not(.react) because react handles it's own event clicks and state
  // and we don't want to interfere with that from outside of react context
  $(".alert.collapsable:not(.react) .toggle-content").on("click", e=> {
    e.preventDefault();
    const alert = $(e.currentTarget).closest(".alert");

    if (alert.hasClass("open")) {
      alert.removeClass("open");
      alert.find(".toggle-content a").text("See more");
    } else {
      alert.addClass("open");
      alert.find(".toggle-content a").text("See less");
    }
  });

  // Automatically show any flash messages
  setTimeout((() => $('.flash .alert-dismissible').slideUp()), 4000);
});
