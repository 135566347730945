$(document).on('turbo:load', ()=> {
  if ($(".service-tabs").length) {
    $(".service-tabs a").on("click", e => {
      // Using replaceState to prevent scrolling from setting window.location.hash
      history.replaceState(history.state, "", e.currentTarget?.dataset?.target?.replace(".", "#"));
    });

    if (document.location.hash.length) {
      $(".service-tabs a[data-target='" + document.location.hash.replace("#", ".") + "']").tab('show');
    }

    // Carousel control resizing may need to be force-invoked once a tab is shown...
    if ($(".carousel").length) {
      $('a[data-toggle="tab"]').on('shown.bs.tab', e => {
        setTimeout(() => {
          const event = document.createEvent('HTMLEvents');
          event.initEvent('resize', true, false);
          window.dispatchEvent(event);
        } , 200);
      });
    }
  }
});
