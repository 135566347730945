import moment from 'moment';

export const getAutosaveKeyPrefix = () => {
  const userDataElement = document.getElementById("user-data-tag");
  const userData = userDataElement ? JSON.parse(userDataElement.textContent) : {};

  return `loomly-user_${userData.id}-`;
}

export const getAutosaveKey = (prefix, id, calendarId) => {
  return `${getAutosaveKeyPrefix()}calendar_${calendarId}-${prefix}_${id || "draft"}`;
}

export const getAutosaveDraft = (prefix, id, calendarId) => {
  const key = getAutosaveKey(prefix, id, calendarId);

  try {
    const draft = JSON.parse(localStorage.getItem(key));
    if (!draft) {
      return null;
    }

    const expiry = moment(draft.savedAt).add(6, 'hours');
    if (expiry.isSameOrAfter(moment())) {
      return draft;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
}

export const getAutosaveAlert = (prefix, id, calendarId) => {
  const draft = getAutosaveDraft(prefix, id, calendarId);
  if (!draft) {
    return "";
  }

  const expiry = moment(draft.savedAt).add(6, 'hours');
  const objectName = { post_v2: "post" }[prefix] || prefix;

  let alert =  id ? `The backup of this ${objectName} in your browser is different from the version below.<br>`
                  : `There is an unsaved backup of a draft ${objectName} in your browser.<br>`

  alert += " Please note that making any changes below will overwrite this backup permanently.";
  alert += ` This backup will expire in approximately ${expiry.fromNow(true)}.`;

  return alert;
}
