import moment from 'moment';
import isEqualWith from 'lodash/isEqualWith';

export function formParamsAsObject(form) {
  return form.serializeArray().reduce((object, entry)=> {
    if (entry.name.endsWith("[]")) {
      const newKey = entry.name.replace(/\[\]$/, "");
      object[newKey] = object[newKey] || [];
      object[newKey].push(entry.value);
    } else {
      object[entry.name] = entry.value;
    }

    return object;
  }, {});
}

export function isEqual(a, b) {
  return isEqualWith(a, b, (x, y) => {
    if (x instanceof moment) {
      return x.isSame(y);
    }
    if (y instanceof moment) {
      return y.isSame(x);
    }
  })
}
